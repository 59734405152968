import { extendTheme } from '@chakra-ui/react';

export const bloodStoneTheme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  colors: {
    'racing-red': '#FF0101',
  }
});