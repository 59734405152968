import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  Text,
  Spacer,
  VStack,
  Container,
  Center,
  HStack,
  StackDivider, Wrap,
} from '@chakra-ui/react';
import { bloodStoneTheme } from './theme/theme';
import { Logo } from './components/Logo';

function App() {
  return (
    <ChakraProvider theme={bloodStoneTheme}>
      <Box textAlign="center" fontSize="xl" >
        <Grid minH="100vh" gridTemplateRows='1fr auto'>
          <Center>
            <Logo/>
          </Center>

          <Center color='gray.600'>
            <VStack pb='3'>
              <Text>A talented group of entrepreneurs</Text>
              <Grid divider={<StackDivider/>} templateColumns={['1fr', '1fr 1fr']} templateRows={['1fr, 1fr', '1fr']}>
                <Text>contact@bloodstonegroup.org</Text>
                <Text>1541 Ocean Ave Suite 200, Santa Monica, CA 90401</Text>
              </Grid>
            </VStack>
          </Center>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
