import { Heading, HStack, useColorModeValue, useToken } from '@chakra-ui/react';
import { useSpring } from 'framer-motion';

// width="79" height="95"

export const Logo = () => {
  const [red] = useToken('colors', ['racing-red']);

  return (
    <HStack spacing='4'>
      {/*<svg height='3em' viewBox="0 0 79 95" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(26deg)'}}>*/}
      {/*  <path*/}
      {/*    strokeWidth='2'*/}
      {/*    d="M48.451 1L78 25M48.451 1L22.3529 1M48.451 1L44.1373 13.7857M78 25L71.5294 71.0714M78 25L53.4118 58.6429M71.5294 71.0714L53.4118 86.0714M71.5294 71.0714L53.4118 58.6429M53.4118 86.0714L34 94M53.4118 86.0714L53.4118 58.6429M34 94L22.3529 82M34 94L35.1072 53.9286M22.3529 82L9.41176 78.3571L1 29.0714M22.3529 82L14.3725 49.4286M1 29.0714L22.3529 1M1 29.0714L14.3725 49.4286M22.3529 1L29.902 13.7857M35.5098 39.3571L44.1373 13.7857M35.5098 39.3571L53.4118 58.6429M35.5098 39.3571L35.1072 53.9286M35.5098 39.3571L14.3725 49.4286M53.4118 58.6429L44.1373 13.7857M53.4118 58.6429L35.1072 53.9286M44.1373 13.7857H29.902M35.1072 53.9286L14.3725 49.4286M14.3725 49.4286L29.902 13.7857"*/}
      {/*    stroke={red}*/}
      {/*  />*/}
      {/*</svg>*/}
      <Heading fontFamily='Vampiro One' color='racing-red' fontSize='3em' fontWeight='100'>
        BloodStone
      </Heading>
    </HStack>
  )
}